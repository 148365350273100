import { type AsyncData } from 'nuxt/dist/app/composables'
import { type ResponseConfig } from './types/Response'
import type { IAuthToken } from './types'

const baseUrl = import.meta.env.VITE_API_HOST
let tokenState = {} as IAuthToken | null

if (process.client) {
  const token = useCookie('t')
  if (token.value) {
    tokenState = base64ToObject(token.value as string)
  }
}

// 添加一個方法來更新 tokenState
function setToken(newToken: string | null) {
  tokenState = newToken ? base64ToObject(newToken) : null
}

const fetch = (url: string, options?: any): Promise<AsyncData<any, Error>> => {
  const reqUrl = baseUrl + url
  const authorization = tokenState ? `${tokenState?.token_type} ${tokenState?.token}` : null

  const headers = {
    ...options.headers
  }
  if (authorization !== null) {
    headers['Authorization'] = authorization
  }

  // console.log('Authorization', authorization)

  headers['Accept'] = 'application/json'

  return new Promise((resolve, reject) => {
    useFetch<ResponseConfig>(reqUrl, {
      ...options,
      headers
    })
      .then(({ data, error }) => {
        if (error.value) {
          reject({
            error: error.value,
            status: error.value.statusCode,
            data: error.value.data
          })
          return
        }
        resolve(data.value)
      })
      .catch(err => {
        reject(err)
      })
  })
}

export default new (class Http {
  setToken = setToken

  get(url: string, params?: any): Promise<any> {
    return fetch(url, { method: 'get', params })
  }

  post(url: string, body?: any): Promise<any> {
    return fetch(url, { method: 'post', body })
  }

  put(url: string, body?: any): Promise<any> {
    return fetch(url, { method: 'put', body })
  }

  delete(url: string, body?: any): Promise<any> {
    return fetch(url, { method: 'delete', body })
  }
})()
