export default defineNuxtRouteMiddleware(to => {
  const originalUrl = to.path
  if (originalUrl !== '/') {
    const normalizedUrl = originalUrl.toLowerCase().replace(/\/$/, '')

    if (originalUrl !== normalizedUrl) {
      return navigateTo(normalizedUrl, { redirectCode: 301 })
    }
  }
})
